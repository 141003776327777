import React from "react";
import "./App.css";
import "@fontsource/dm-sans";

export default function PageAccueil(props) {
  return (
    <>
      <h1
        style={{
          fontFamily: "Helvetica",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        {props.lang == "francais" &&
          "TRACEUR, la Blockchain au service de la traçabilité d'origine et de la traçabilté carbone"}
        {props.lang == "english" &&
         <p>TRACEUR, Blockchain-backed traceability <br /> of origin and carbon print</p> }
      </h1>
      {/* <br /> */}
      <div className="image-container">
        <img
          // src="fruits-and-vegetables-732x549.jpg"
          src="supermarkets.jpeg"
          alt="rayons de supermarché"
          // height="300px"
          width="70%"
        />
      </div>
      <br />
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "70%" }}>
          {/*       <p style={{ fontFamily: "Helvetica", width: "70%" , textAlign: "justify" }}>   */}
          Le système Traceur permet aux consommateurs grâce à une application
          mobile d'explorer la chaîne de fabrication des produits et de
          connaitre l'origine des ingrédients, celle des ingrédients des
          ingrédients eux-mêmes, et ainsi de suite. C'est la traçabilité récursive.
          <br /> Ils ont aussi accès à l'empreinte carbone des produits, calculée à partir de celles des ingrédients.
          <br />Cette appli mobile accéde à une base de données sécurisée
          (blockchain) contenant les références de tous les lots de tous les
          fournisseurs. Les industriels alimentent cette base à l'occasion de la
          sortie de chacun de leurs lots de fabrication.
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "70%" }}>
          {/*       <p style={{ fontFamily: "Helvetica", width: "70%" , textAlign: "justify" }}>   */}
          The Traceur system allows consumers, through a mobile application, to
          explore the manufacturing chain of products and to know the origin of
          the ingredients, that of the ingredients of the ingredients
          themselves, and so on. It's recursive traceability.
          <br /> They also can see the carbon print of products, computed from the carbon prints of the ingredients.
          <br /> This mobile application accesses a secure database (blockchain)
          containing the references of all batches from all suppliers.
          Manufacturers feed this database when each of their manufacturing
          batches is released.
        </p>
      )}

      <br />
      <br />

      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          La traçabilité, une exigence croissante des consommateurs <br />
          et un avantage compétitif pour les fournisseurs
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Traceability, a growing consumer demand <br />
          and a competitive advantage for suppliers
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      <img
        className="small-image-container"
        src="boites-conserve.jpeg"
        alt="produits d'origine inconnue"
        // height="100px"
        width="30%"
      />
      {/* </div> */}

      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          Les consommateurs veulent connaître le contenu des produits qu'ils
          mettent dans leur assiette (ou des vêtements qu'ils portent) et d'une façon générale veulent savoir d'où
          viennent les produits qu'ils achètent ainsi que leurs ingrédients. C'est un plus de leur donner aussi l'empreinte carbone des produits. 
          <br />
          <br />
          Gagner la confiance de leurs clients est un enjeu majeur pour les
          industriels. Il faut alors donner un moyen simple au consommateur de
          connaître l'origine des ingrédients du produit. Il faut lui donner
          aussi la même information concernant les ingrédients eux-mêmes en
          fournissant, autant que possible, l'ensemble de la chaîne de
          fabrication.
          <br />
          C'est exactement ce que permet le système Traceur.
          <br />
        </p>
      )}

      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          Consumers want to know the content of the products they put in their
          plate (or of the clothes they wear) and generally want to know where the products they buy come from
          as well as their ingredients. It is also valuable to give them the carbon print of the products.
          <br />
          <br />
          Gaining the trust of their customers is a major challenge for
          manufacturers. It is therefore necessary to provide a simple way for
          the consumer to know the origin of the product's ingredients. It is
          also necessary to give them the same information concerning the
          ingredients themselves by providing, as much as possible, the entire
          manufacturing chain.
          <br />
          This is exactly what the Traceur system allows.
          <br />
        </p>
      )}

      <br />
      <br />

      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Le système Traceur permet aux fournisseurs de déclarer <br />
          chaque lot de production ainsi que ses lots ingrédients
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The Traceur system allows suppliers to declare <br />
          each production batch as well as its ingredient batches
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      <img
        className="small-image-container"
        src="chaine-alimentaire.png"
        alt="chaine de production alimentaire"
        // height="100"
        width="50%"
      />

      {/* </div> */}

      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          Tous les produits relevant d'un même lot de fabrication ont les mêmes
          caractéristiques. C'est son lot de production auquel il faut rattacher
          un produit. Un lot de production peut correspondre à des milliers de
          produits qui partageront les mêmes informations.
          <br />
          <br />A l'occasion de la production de chaque lot, l'industriel entre
          dans la base de données sécurisée (blockchain), grâce à un site Web
          dédié, les caractéristiques du lot ainsi que les références des lots
          ingrédients utilisés. Le système Traceur pourra ainsi reconstituer
          toute la chaîne de fabrication ainsi que les empreintes carbone.
          <br />
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          All products in the same manufacturing batch have the same
          characteristics. It is its production batch to which a product must be
          attached. A production batch can correspond to thousands of products
          that will share the same information.
          <br />
          <br />
          When each batch is produced, the manufacturer enters in the secure
          database (blockchain), using a dedicated website, the characteristics
          of the batch as well as the references of the ingredient batches
          used. The Traceur system will thus be able to reconstruct the entire
          manufacturing chain including the carbon prints.
          <br />
        </p>
      )}

      <br />
      <br />

      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          L'application mobile Cherche Youki! permet aux consommateurs d'accéder
          à toute la chaîne de traçabilité d'un produit <br /> en scannant un
          qr-code
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The Cherche Youki! mobile application allows consumers to access the
          entire traceability chain of a product by scanning a qr-code
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      <img
        className="small-image-container"
        src="scan.webp"
        alt="scan par application mobile"
        // height="100"
        width="40%"
      />

      {/* </div> */}
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          L'application mobile "Cherche Youki", disponible sur Iphone et
          Android, permet aux consommateurs d'avoir accès à toutes les
          informations concernant un produit en scannant un qr-code figurant sur
          l'etiquette. Ils accèderont ainsi aux informations concernant le
          produit, ses ingrédients, les ingrédients des ingrédients et ainsi de
          suite. C'est l'accès à toute la chaîne de fabrication qui leur est
          proposé, offrant ainsi une traçabilité unique.
          <br />
          <br />
          La traçabilité ainsi offerte augmentera la confiance des
          consommateurs, donnant au fournisseur un avantage compétitif majeur.
          <br />
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          {/* <br /> */}
          The mobile application "Cherche Youki", available on iPhone and
          Android, allows consumers to have access to all the information
          concerning a product by scanning a QR code on the label. They will
          thus access information concerning the product, its ingredients, the
          ingredients of the ingredients and so on. This is the access to the entire
          manufacturing chain that is offered to them, thus offering unique
          traceability.
          <br />
          <br />
          The traceability thus offered will increase consumer confidence,
          giving the supplier a major competitive advantage.
          <br />
        </p>
      )}

      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
      <br />
    </>
  );
}
